import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { LocalStore } from "../../helper/LocalStore";

//define type for auth state
interface AuthState {
  isAuth: Boolean;
}

const initialState: AuthState = {
  isAuth: !!LocalStore.getRefreshToken(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuth = true;
    },
    logout: (state) => {
      state.isAuth = false;
    },
  },
});

export const { login, logout } = authSlice.actions;

export const useAuth = (state: RootState) => state.auth.isAuth;

export default authSlice.reducer;
