import { MutateFunction } from "react-query";
import { api } from "../../../api";

export const getUniversityDetails = async () => {
  return await api.get(`university`);
};

export const getAllUniversityDetails: MutateFunction<
  UniversityProps,
  Partial<UniversityProps>,
  {
    universities: number[];
    courses: number[];
    streams: number[];
    semesters: number[];
    subjects: number[];
    limit: number;
    offset: number;
  }
> = async ({
  universities,
  courses,
  streams,
  semesters,
  subjects,
  limit,
  offset,
}) => {
  return api.post(`university/all-university`, undefined, {
    universities,
    courses,
    streams,
    semesters,
    subjects,
    limit,
    offset,
  });
};
export const getUniversityById = async ({ queryKey }: any) => {
  const [_, id] = queryKey;
  if (!id) {
    return { data: [] };
  }
  return await api.get(`university/id/${id}`);
};

export const updateUniversity: MutateFunction<
  UniversityProps,
  Partial<UniversityProps>,
  { payload: UniversitiesProps; id: number }
> = async ({ payload, id }) => {
  return api.patch(`university/update`, undefined, payload);
};

export const createUniversity: MutateFunction<
  unknown,
  UniversitiesProps,
  { payload: UniversitiesProps }
> = async ({ payload }) => {
  console.log(payload);

  return api.post(`university/create`, undefined, payload);
};

export const deleteUniversity: MutateFunction<
  unknown,
  any,
  { id: number }
> = async ({ id }) => {
  return api.delete(`university/${id}`);
};

export const getAllData = async () => {
  return await api.get(`university/all-datas`);
};
