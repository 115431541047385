import { MutateFunction } from "react-query";
import { api } from "../api";
import { AuthLoginProps } from "../typing/auth";

export const authLoginWithPassword: MutateFunction<
  { status: boolean; message: string; refreshToken: string },
  Partial<AuthLoginProps>,
  AuthLoginProps
> = (payload: AuthLoginProps) => {
  return api.post("auth/login", undefined, payload);
};

export async function exchangeRefreshToken(token: string) {
  return await api.get(`auth/exchange?token=${token}`);
}
