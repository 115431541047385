import React, { SyntheticEvent, useState } from "react";
import {
  Table,
  ScrollArea,
  Grid,
  Button,
  Divider,
  ActionIcon,
} from "@mantine/core";
import { Edit, Archive } from "tabler-icons-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteHashtag, getHashtagList } from "../../queries/hashtagQueries";
import { HashtagForm } from "./Form";

const HashtagsView = () => {
  const [isOpenForm, setOpenForm] = useState(false);
  const [data, setData] = useState<HashtagProps | null>(null);

  const queryClient = useQueryClient();

  const { mutate: mutDeleteHashtag } = useMutation(deleteHashtag, {
    onSuccess: (newData) => {
      queryClient.invalidateQueries();
    },
  });

  const { isLoading, data: hashtagData = [] } = useQuery(
    "Hashtags",
    getHashtagList,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const onUpdateData = (payload: HashtagProps) => {
    setData(payload);
    setOpenForm(true);
  };

  const onRestForm = () => {
    setOpenForm(false);
    setData(null);
  };

  const rows =
    hashtagData &&
    hashtagData.map((row: HashtagProps) => {
      return (
        <tr key={row.id}>
          <td>{row.id}</td>
          <td>{row.hashtag}</td>
          <td>
            <Grid columns={8}>
              <Grid.Col span={1}>
                <ActionIcon
                  title="Update"
                  onClick={() => onUpdateData({ ...row })}
                >
                  <Edit size={25} strokeWidth={2} color={"green"} />
                </ActionIcon>
              </Grid.Col>
              <Grid.Col span={1}>
                <ActionIcon
                  title="Delete"
                  onClick={() => mutDeleteHashtag({ id: Number(row.id) })}
                >
                  <Archive size={25} strokeWidth={2} color={"red"} />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          </td>
        </tr>
      );
    });

  if (isLoading) {
    // TODO loader on data loading
    return null;
  }
  return (
    <>
      <Grid justify={"flex-end"}>
        <Grid.Col lg={1}>
          <Button
            variant="light"
            onClick={(e: SyntheticEvent) => setOpenForm(true)}
          >
            Create
          </Button>
        </Grid.Col>

        <Grid.Col>
          <Divider />
          <ScrollArea
            offsetScrollbars
            style={{ height: 700, padding: 15 }}
            scrollHideDelay={1000}
          >
            <Table
              sx={{ minWidth: 800 }}
              verticalSpacing="xs"
              horizontalSpacing={"xl"}
            >
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>Hashtags</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
      </Grid>
      <HashtagForm
        isOpenModal={isOpenForm}
        setOpenForm={setOpenForm}
        onReset={onRestForm}
        data={data}
      />
    </>
  );
};

export default HashtagsView;
