import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteFeedback, getFeedbacks } from "../../queries/feedback";
import { FeedbackProps } from "../../typing/feedback";
import { Divider, Grid, ScrollArea, Table, ActionIcon } from "@mantine/core";
import { Archive } from "tabler-icons-react";

const Feedbacks = () => {
  const { isLoading, data: feedbackData = [] } = useQuery(
    "feedback",
    getFeedbacks,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const queryClient = useQueryClient();
  const { mutate: mutDeleteUser } = useMutation(deleteFeedback, {
    onSuccess: (newData) => {
      queryClient.invalidateQueries();
    },
  });

  const rows =
    feedbackData.data &&
    feedbackData.data.map((row: FeedbackProps) => {
      return (
        <tr key={row.id}>
          <td>{row.user_id}</td>
          <td>{row.tag}</td>
          <td>{row.description}</td>
          <td>{row.section}</td>
          <td>
            <Grid columns={2}>
              <Grid.Col span={1}>
                <ActionIcon
                  title="Delete"
                  onClick={() => mutDeleteUser(row.id)}
                >
                  <Archive size={25} strokeWidth={2} color={"red"} />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          </td>
        </tr>
      );
    });

  if (isLoading) {
    // TODO loader on data loading
    return null;
  }
  return (
    <>
      <Grid justify={"flex-end"}>
        {/* <Grid.Col lg={1}>
            <Button
              variant="light"
              onClick={(e: SyntheticEvent) => setOpenForm(true)}
            >
              Create
            </Button>
          </Grid.Col> */}

        <Grid.Col>
          <Divider />
          <ScrollArea
            offsetScrollbars
            style={{ height: 700, padding: 15 }}
            scrollHideDelay={1000}
          >
            <Table
              sx={{ minWidth: 800 }}
              verticalSpacing="xs"
              horizontalSpacing={"xl"}
            >
              <thead>
                <tr>
                  <th>User Id</th>
                  <th>Tag</th>
                  <th>Description</th>
                  <th>Section</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Feedbacks;
