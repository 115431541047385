import React, { SyntheticEvent, useState } from "react";
import {
  createStyles,
  Table,
  Anchor,
  ScrollArea,
  Grid,
  Button,
  Divider,
  ActionIcon,
  Pagination,
} from "@mantine/core";
import { Edit, Archive } from "tabler-icons-react";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllChannelNames } from "../../queries/channelQueries";
import {
  deletePost,
  getAllNotes,
  getCategories,
  getHashtags,
} from "../../queries/notesQueries";
import { NotesProps } from "../../typing/notesTypes";
import NotesForm from "./notesForm";
import dayjs from "dayjs";
const useStyles = createStyles((theme) => ({
  progressBar: {
    "&:not(:first-of-type)": {
      borderLeft: `3px solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
        }`,
    },
  },
}));

const NotesView = () => {
  const { classes, theme } = useStyles();
  const [isOpenForm, setOpenForm] = useState(false);
  const [updateData, setUpdateData] = useState<NotesProps | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const queryClient = useQueryClient();
  const { isLoading: isLoadingChannels, data: channelList } = useQuery(
    "channelsList",
    getAllChannelNames,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading: isLoadingHashtags, data: hashtagList } = useQuery(
    "hashtags",
    getHashtags,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading: isLoadingCat, data: categoryList } = useQuery(
    "categories",
    getCategories,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading, data: notesData = [] } = useQuery(
    ["notes", page],
    getAllNotes,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const onUpdateData = (data: any) => {
    let payload = Object.assign({}, data);

    payload.normal_note = payload.normal_note;

    payload.audio = payload.audio;

    payload.video = payload.video;
    payload.youtube = payload.youtube;

    setUpdateData(payload);
    setOpenForm(true);
  };

  const { mutate: mutDeleteNotes } = useMutation(deletePost, {
    onSuccess: (newData) => {
      queryClient.invalidateQueries();
    },
  });
  const onRestForm = () => {
    setOpenForm(false);
    setUpdateData(null);
  };
  const totalPages = Math.ceil((notesData?.length ?? 0) / rowsPerPage);

  const rows =
    notesData &&
    notesData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row: NotesProps) => {
        console.log(row);

        return (
          <tr key={row.note_setup.id}>
            <td>{row.note_setup.id}</td>
            <td>{row.note_setup.channel_name}</td>
            <td>{row.note_setup.hashtags}</td>
            <td>{row.note_setup.note_category}</td>
            <td>{row.note_setup.listing_order}</td>
            <td>
              <Anchor<"a">
                size="sm"
                onClick={(event) => event.preventDefault()}
              >
                {row.note_setup.link_open}
              </Anchor>
            </td>
            <td>
              <Anchor<"a">
                size="sm"
                onClick={(event) => event.preventDefault()}
              >
                {row.note_setup.link}
              </Anchor>
            </td>
            <td>{row.note_setup.description}</td>
            <td>{row.note_setup.prepared_by}</td>
            <td>{row.note_setup.modules_covered}</td>
            <td>{row.note_setup.avg_rating}</td>
            <td>{row.note_setup.total_rating}</td>
            <td>{row.note_setup.note_image}</td>
            <td>{row.note_setup.created_at.toString()}</td>
            <td>{row.note_setup.share_link}</td>
            <td>{row.note_setup.share_text}</td>
            <td>{row.note_setup.share_image}</td>
            <td>{row.note_setup.university.name}</td>
            <td>{row.note_setup.course.name}</td>
            <td>{row.note_setup.stream.name}</td>
            <td>{row.note_setup.semester.name}</td>
            <td>{row.note_setup.subject.name}</td>
            <td>{row.note_setup.publish?.toString()}</td>

            <td>
              <Grid columns={2}>
                <Grid.Col span={1}>
                  <ActionIcon
                    title="Update"
                    onClick={() => onUpdateData({ ...row })}
                  >
                    <Edit size={25} strokeWidth={2} color={"green"} />
                  </ActionIcon>
                </Grid.Col>
                <Grid.Col span={1}>
                  <ActionIcon
                    title="Delete"
                    onClick={() => mutDeleteNotes(row.note_setup.id as number)}
                  >
                    <Archive size={25} strokeWidth={2} color={"red"} />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            </td>
          </tr>
        );
      });

  if (isLoading) {
    // TODO loader on data loading
    return null;
  }
  return (
    <>
      <Grid justify={"flex-end"}>
        <Grid.Col lg={1}>
          <Button
            variant="light"
            onClick={(e: SyntheticEvent) => setOpenForm(true)}
          >
            Create
          </Button>
        </Grid.Col>

        <Grid.Col>
          <Divider />
          <ScrollArea
            offsetScrollbars
            style={{ height: 700, padding: 15 }}
            scrollHideDelay={1000}
          >
            <Table
              sx={{ minWidth: 800 }}
              verticalSpacing="xs"
              horizontalSpacing={"xl"}
            >
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Channel</th>
                  <th>Hashtags</th>
                  <th>Note Category</th>
                  <th>Listing Order</th>
                  <th>Link Open</th>
                  <th>Link</th>
                  <th>Description</th>
                  <th>Prepared By</th>
                  <th>Modules Covered</th>
                  <th>Average Rating</th>
                  <th>Total Rating</th>
                  <th>Note Image</th>
                  <th>Created At</th>
                  <th>Share Link</th>
                  <th>Share Text</th>
                  <th>Share Image</th>
                  <th>Univeristy</th>
                  <th>Course</th>
                  <th>Stream</th>
                  <th>Semester</th>
                  <th>Subject</th>
                  <th>Publish</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
        <Grid.Col>
          <Pagination
            disabled={!notesData.length}
            position="right"
            page={page + 1}
            onChange={(page: number) => setPage(page - 1)}
            total={totalPages}
          />
        </Grid.Col>
      </Grid>
      <NotesForm
        isOpenModal={isOpenForm}
        onReset={onRestForm}
        data={updateData}
        channels={channelList?.data}
        hashtagData={hashtagList?.data}
        categories={categoryList?.data}
      />
    </>
  );
};

export default NotesView;
