import { MutateFunction } from "react-query";
import { api } from "../../../api";

export const getSubjectDetails = async () => {
  return await api.get(`subject`);
};

export const updateSubject: MutateFunction<
  SubjectProps,
  Partial<SubjectProps>,
  { payload: SubjectProps; id: number }
> = async ({ payload, id }) => {
  return api.patch(`subject/${id}`, undefined, payload);
};

export const createSubject: MutateFunction<
  unknown,
  SubjectProps,
  { payload: SubjectProps }
> = async ({ payload }) => {
  return api.post(`subject`, undefined, payload);
};

export const deleteSubject: MutateFunction<
  unknown,
  any,
  { id: number }
> = async ({ id }) => {
  return api.delete(`subject/${id}`);
};
