import React, { useState } from "react";
import { Table, ScrollArea, Grid, Divider, ActionIcon } from "@mantine/core";
import { Edit } from "tabler-icons-react";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { deleteUser } from "../../queries/userQuery";
import { userProps } from "../../typing/users";
import { getAppSetupDetails } from "../../queries/appSetup";
import { AppSetupProps } from "../../typing/appSetup";
import { AppSetupForm } from "./AppSetupForm";

const AppSetup = () => {
  const [isOpenForm, setOpenForm] = useState(false);
  const [updateData, setUpdateData] = useState<AppSetupProps | null>(null);

  const { isLoading, data: appSetupData = [] } = useQuery(
    "appDetails",
    getAppSetupDetails,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const onUpdateData = (payload: AppSetupProps) => {
    setUpdateData(payload);
    setOpenForm(true);
  };

  const onRestForm = () => {
    setOpenForm(false);
    setUpdateData(null);
  };

  console.log(appSetupData);
  

  const rows =appSetupData?.map((row: AppSetupProps) => {
      return (
        <tr key={row.id}>
          <td>{row.new_version}</td>
          <td>{row.force_update_version}</td>
          <td>{row.force_update?.toString()}</td>
          <td>{row.share_app_image}</td>
          <td>{row.share_app_content}</td>
          <td>{row.update_link}</td>
          <td>{row.whatsapp_link}</td>
          <td>{row.server_down?.toString()}</td>

          <td>
            <Grid columns={2}>
              <Grid.Col span={1}>
                <ActionIcon
                  title="Update"
                  onClick={() => onUpdateData({ ...row })}
                >
                  <Edit size={25} strokeWidth={2} color={"green"} />
                </ActionIcon>
              </Grid.Col>
              {/* <Grid.Col span={1}>
                <ActionIcon
                  title="Delete"
                  onClick={() => mutDeleteUser(row.user_id)}
                >
                  <Archive size={25} strokeWidth={2} color={"red"} />
                </ActionIcon>
              </Grid.Col> */}
            </Grid>
          </td>
        </tr>
      );
    });

  if (isLoading) {
    // TODO loader on data loading
    return null;
  }
  return (
    <>
      <Grid justify={"flex-end"}>
        {/* <Grid.Col lg={1}>
          <Button
            variant="light"
            onClick={(e: SyntheticEvent) => setOpenForm(true)}
          >
            Create
          </Button>
        </Grid.Col> */}

        <Grid.Col>
          <Divider />
          <ScrollArea
            offsetScrollbars
            style={{ height: 700, padding: 15 }}
            scrollHideDelay={1000}
          >
            <Table
              sx={{ minWidth: 800 }}
              verticalSpacing="xs"
              horizontalSpacing={"xl"}
            >
              <thead>
                <tr>
                  <th>New Version</th>
                  <th>Force Update Version</th>
                  <th>Force Update</th>
                  <th>Share App Image</th>
                  <th>Share APP Content</th>
                  <th>Update Link</th>
                  <th>Whatsapp Link</th>
                  <th>server Down</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
      </Grid>
      <AppSetupForm
        isOpenModal={isOpenForm}
        setOpenForm={setOpenForm}
        onReset={onRestForm}
        data={updateData}
      />
    </>
  );
};

export default AppSetup;
