const LOCAL_STORE_REFRESH_TOKEN = "@cc_refresh_token";
export class LocalStore {
  static saveRefreshToken(token: string) {
    localStorage.setItem(LOCAL_STORE_REFRESH_TOKEN, token);
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem(LOCAL_STORE_REFRESH_TOKEN) as string;
  }

  static removeToken() {
    return localStorage.removeItem(LOCAL_STORE_REFRESH_TOKEN);
  }
}
