import React from "react";
import { MutateFunction } from "react-query";
import { api } from "../api";

export const getHashtagList = async () => {
  return await api.get(`hashtags`);
};

export const updateHashtag: MutateFunction<
  HashtagProps,
  Partial<HashtagProps>,
  { payload: HashtagProps; id: number }
> = async ({ payload, id }) => {
  return api.patch(`hashtags/${id}`, undefined, payload);
};

export const createHashtag: MutateFunction<
  unknown,
  HashtagProps,
  { payload: HashtagProps }
> = async ({ payload }) => {
  return api.post(`hashtags`, undefined, payload);
};

export const deleteHashtag: MutateFunction<
  unknown,
  any,
  { id: number }
> = async ({ id }) => {
  return api.delete(`hashtags/${id}`);
};
