import React from "react";
import { MutateFunction } from "react-query";
import { api } from "../api";
import { ChannelProps } from "../typing/channels";

export const getAllChannels = async ({ queryKey }: any) => {
  const [_, language] = queryKey;
  return await api.get(`channel/list`);
};

export const createChannel: MutateFunction<
  ChannelProps,
  Partial<ChannelProps>,
  ChannelProps
> = async (payload: any) => {
  return api.post("channel/new", undefined, payload);
};

export const updateChannel: MutateFunction<
  ChannelProps,
  Partial<ChannelProps>,
  { payload: ChannelProps; id: number }
> = async ({ payload, id }) => {
  return api.patch(`channel/update/${id}`, undefined, payload);
};

export const deleteChannel: MutateFunction<string> = (id) => {
  return api.delete(`channel/delete/${id}`);
};

export const getAllChannelNames = async () => {
  return api.get(`channel/all`);
};
