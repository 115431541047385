import { MutateFunction } from "react-query";
import { api } from "../api";
import { FeedProps } from "../typing/feedTypes";

export const getAllFeeds = async () => {
  return await api.get(`feed/list`);
};
export const getAllFeedsFilter = async ({ queryKey }: any) => {
  const [_, page] = queryKey;
  return await api.get(`feed/list/filters?offset=${page}&limit=10`);
};

export const createNewPost: MutateFunction<
  FeedProps,
  Partial<FeedProps>,
  any
> = async (payload) => {
  return api.post("feed/newPost", undefined, payload);
};

export const updatePost: MutateFunction<
  FeedProps,
  Partial<FeedProps>,
  { payload: any; id: number }
> = async ({ payload, id }) => {
  let stringifyPayload = Object.assign(payload, {
    post_image: JSON.stringify(payload.post_image),
    flash_card: JSON.stringify(payload.flash_card),
    quiz_fight: JSON.stringify(payload.quiz_fight),
    quiz: JSON.stringify(payload.quiz),
    exam: JSON.stringify(payload.exam),
    poll: JSON.stringify(payload.poll),
    youtube: JSON.stringify(payload.youtube),
    video: JSON.stringify(payload.video),
    audio: JSON.stringify(payload.audio),
  });

  return api.patch(`feed/update/${id}`, undefined, stringifyPayload);
};
export const getHashtags = async () => {
  return api.get("feed/hashtags");
};

export const getCategories = async () => {
  return api.get("feed/categories");
};

export const deletePost: MutateFunction<string, any, number> = (id) => {
  return api.delete(`feed/delete/${id}`);
};
