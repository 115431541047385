import { Button, Grid, Modal, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { updateAppSetup } from "../../queries/appSetup";
import { AppSetupProps } from "../../typing/appSetup";

export const AppSetupForm = (props: any) => {
  const queryClient = useQueryClient();

  const { mutate: mutUpdateAppSetup } = useMutation(updateAppSetup, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });
  useEffect(() => {
    if (props.data) setValues(props.data);
  }, [props.data]);
  const form = useForm({
    initialValues: {
      id: 0,
      new_version: "",
      force_update_version: "",
      force_update: false,
      share_app_image: "",
      share_app_content: "",
      update_link: "",
      whatsapp_link: "",
      server_down: false,
    } as AppSetupProps,
    validate: {},
  });

  const { onSubmit, reset, errors, values, setFieldValue, setValues } = form;

  return (
    <Modal
      opened={props.isOpenModal}
      onClose={() => {
        reset();
        props.setOpenForm(false);
      }}
      title="Update App setup"
      centered
      size={"xl"}
      closeOnClickOutside={false}
    >
      <form
        onSubmit={onSubmit((payload) => {
          if (payload.id) {
            mutUpdateAppSetup({ payload, id: payload.id });
          }
        })}
      >
        <Grid justify={"flex-start"}>
          <Grid.Col span={6}>
            <TextInput
              label="New Version"
              placeholder="New Version"
              data-autofocus
              value={values.new_version}
              onChange={(event) =>
                setFieldValue("new_version", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Force update Version"
              placeholder="Force Update Version"
              data-autofocus
              value={values.force_update_version}
              onChange={(event) =>
                setFieldValue("force_update_version", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Switch
              style={{ paddingTop: 18 }}
              label="Force Update"
              checked={values.force_update}
              onChange={(event) =>
                setFieldValue("force_update", event.currentTarget.checked)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Share App Image"
              placeholder="Share App Image"
              data-autofocus
              value={values.share_app_image}
              onChange={(event) =>
                setFieldValue("share_app_image", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Share App Content"
              placeholder="Share App Content"
              data-autofocus
              value={values.share_app_content}
              onChange={(event) =>
                setFieldValue("share_app_content", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Update Link"
              placeholder="Update Link"
              data-autofocus
              value={values.update_link}
              onChange={(event) =>
                setFieldValue("update_link", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Whatsapp Link"
              placeholder="Whatsapp Link"
              data-autofocus
              value={values.whatsapp_link}
              onChange={(event) =>
                setFieldValue("whatsapp_link", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Switch
              style={{ paddingTop: 18 }}
              label="Server Down"
              checked={values.server_down}
              onChange={(event) =>
                setFieldValue("server_down", event.currentTarget.checked)
              }
            />
          </Grid.Col>

          <Grid.Col span={3} offset={6}>
            <Button
              fullWidth
              onClick={reset}
              mt="md"
              color={"red"}
              variant={"light"}
            >
              Clear
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button fullWidth type="submit" mt="md" variant="light">
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};
