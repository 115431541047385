import { Button, Grid, Modal, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createCourse, updateCourse } from "./queries";

export const Form = (props: any) => {
  const queryClient = useQueryClient();

  const { mutate: mutUpdateCourse } = useMutation(updateCourse, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });

  const { mutate: mutCreateCourse } = useMutation(createCourse, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      reset(); 
      props.onReset();
    },
  });


  const form = useForm({
    initialValues: {
      name: "",
      code: "",
      available_on_registration: false,
      available_on_filter: false,
      isactive: true,
    } as CourseProps,
    validate: {},
  });

  const { onSubmit, reset, errors, values, setFieldValue, setValues } = form;

  useEffect(() => {
    if (props.data) {
      setValues(props.data); 
    } else {
      reset(); 
    }
  }, [props.data, reset, setValues]);

  return (
    <Modal
      opened={props.isOpenModal}
      onClose={() => {
        reset();
        props.setOpenForm(false);
      }}
      title={values.id ? "Update Course" : "Create Course"}
      centered
      size={"xl"}
      closeOnClickOutside={false}
    >
      <form
        onSubmit={onSubmit((payload) => {
          if (payload.id) {
            mutUpdateCourse({ payload, id: payload.id });
          } else mutCreateCourse({ payload });
        })}
      >
        <Grid justify={"flex-start"}>
          <Grid.Col span={6}>
            <TextInput
              label="Course Name"
              placeholder="Course Name"
              data-autofocus
              value={values.name}
              onChange={(event) =>
                setFieldValue("name", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Course Code"
              placeholder="Course Code"
              data-autofocus
              value={values.code}
              onChange={(event) =>
                setFieldValue("code", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Switch
              style={{ paddingTop: 18 }}
              label="Is Active"
              checked={values.isactive}
              onChange={(event) =>
                setFieldValue("isactive", event.currentTarget.checked)
              }
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Switch
              style={{ paddingTop: 18 }}
              label="Available on registration?"
              checked={values.available_on_registration}
              onChange={(event) =>
                setFieldValue(
                  "available_on_registration",
                  event.currentTarget.checked
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Switch
              style={{ paddingTop: 18 }}
              label="Available on filter?"
              checked={values.available_on_filter}
              onChange={(event) =>
                setFieldValue(
                  "available_on_filter",
                  event.currentTarget.checked
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={3} offset={6}>
            <Button
              fullWidth
              onClick={reset}
              mt="md"
              color={"red"}
              variant={"light"}
            >
              Clear
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button fullWidth type="submit" mt="md" variant="light">
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};
