import {
  ActionIcon,
  Button,
  Divider,
  Grid,
  Modal,
  MultiSelect,
  NumberInput,
  Select,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import dayjs from "dayjs";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { Archive } from "tabler-icons-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { NotesDataProps } from "../../typing/notesTypes";
import { useMutation, useQueryClient } from "react-query";
import { createNewNote, updateNote } from "../../queries/notesQueries";
import { getAllUniversityDetails } from "../university/queries";

const initalStates = {
  image_url: "",
  normalPostData: [] as string[],
  youtubeData: {
    thumbnail: "",
    youtube_link: "",
  },
  videoData: {
    thumbnail: "",
    video_link: "",
  },
  audioData: {
    thumbnail: "",
    audio_link: "",
    duration: "",
  },
};
const postType = [
  { value: "normal", label: "Normal" },
  { value: "youtube", label: "Youtube" },
  { value: "flash_card", label: "Flash Card" },
  { value: "video", label: "Video" },
  { value: "quiz", label: "Quiz" },
  { value: "quizfight", label: "Quizfight" },
  { value: "whatsapp", label: "Whatsapp" },
  { value: "extra1", label: "Extra 1" },
  { value: "extra2", label: "Extra 2" },
];

const NotesForm = (props: any) => {
  console.log("props.datad :", props.data);
  const queryClient = useQueryClient();
  const [
    { image_url, normalPostData, youtubeData, videoData, audioData },
    setState,
  ] = useState(initalStates);
  const [streamData, setStreamData] = useState<any[] | []>();
  useEffect(() => {
    getData({
      universities: [],
      courses: [],
      streams: [],
      semesters: [],
      subjects: [],
      limit: 1000,
      offset: 0,
    });
  }, []);
  const { mutate: getData } = useMutation(getAllUniversityDetails, {
    onSuccess: (result: any) => {
      setStreamData(result?.data);
    },
  });

  const { mutate: mutCreatePost } = useMutation(createNewNote, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });
  const { mutate: mutUpdateNote } = useMutation(updateNote, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });
  const resetState = () => {
    setState({ ...initalStates });
  };

  useEffect(() => {
    if (props.data) {
      let notesData = { ...props.data.note_setup };
      notesData.university = notesData.university.id;
      notesData.course = notesData.course.id;
      notesData.stream = notesData.stream.id;
      notesData.semester = notesData.semester.id;
      notesData.subject = notesData.subject.id;

      const hashstagObjec = {
        // hashtags: notesData.hashtags.split(","),
        // post_category: notesData.post_category.split(","),
      };
      const bind_Data = Object.assign(notesData, hashstagObjec);
      console.log(notesData);

      notesData.hashtags = notesData?.hashtags?.split(",");

      setValues(notesData);
      setState((prevState) => ({
        ...prevState,
        flashCardData: props.data.flash_card,
        normalPostData: props.data.normal_note,
        quizFightData: props.data.quiz_fight,
        quizData: props.data.quiz,
        examData: props.data.exam,
        pollData: props.data.poll,
        youtubeData: props.data.youtube,
        videoData: props.data.video,
        audioData: props.data.audio,
      }));
    }

    return () => {
      resetState();
    };
  }, [props.data]);

  const form = useForm({
    initialValues: {
      id: undefined,
      channel_id: null,
      hashtags: [],
      note_category: null,
      channel_thumbnail: "",
      link_open: "",
      channel_name: "",
      note_title: "",
      description: "",
      link: "",
      share_text: "",
      share_link: "",
      share_image: "",
      note_image: "",
      note_type: "",
      channel_subscribed: false,
      whatsapp_join_status: false,
      created_at: new Date(),
      button_text: "",
      button_color: "",
      image_size: "",
      listing_order: undefined,
      publish: false,
      prepared_by: "",
      avg_rating: undefined,
      total_rating: undefined,
      modules_covered: "",
    } as Omit<
      NotesDataProps,
      | "normal_note"
      | "flash_card"
      | "quiz_fight"
      | "quiz"
      | "exam"
      | "poll"
      | "youtube"
      | "video"
      | "audio"
    >,
    validate: {},
  });
  const onFieldChange = (name: string, value: any) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const onSetNormalPosts = () => {
    setState((prevState) => ({
      ...prevState,
      normalPostData: [...normalPostData, image_url],
      image_url: "",
    }));
  };

  const onRemoveNormalPost = (url: string) => {
    setState((prevState) => ({
      ...prevState,
      normalPostData: normalPostData.filter((item) => item !== url),
    }));
  };

  const { onSubmit, reset, errors, values, setFieldValue, setValues } = form;

  const findDistinct = (array: any[], field: string) => {
    if (array.length) {
      const uniqueIds: any = new Set(array.map((obj) => obj[field]));
      const distinctArray = [...uniqueIds].map((id) =>
        array.find((obj) => obj[field] === id)
      );

      return distinctArray;
    } else {
      return [];
    }
  };

  const findUniversities = () => {
    if (streamData?.length) {
      let distUniv: any = findDistinct(streamData, "university_id");
      console.log(distUniv);

      if (distUniv?.length) {
        return distUniv?.map((a: any) => {
          return {
            ...a,
            value: a.university_id,
            label: a.university_name,
          };
        });
      }
    } else {
      return [];
    }
  };
  const findCourses = () => {
    if (values.university) {
      let unv: any = streamData?.filter(
        (u) => u.university_id === values.university
      );
      let distCrs: any = findDistinct(unv, "course_id");
      if (distCrs?.length) {
        return distCrs?.map((a: any) => {
          return {
            ...a,
            value: a.course_id,
            label: a.course_name,
          };
        });
      }
    } else {
      return [];
    }
  };
  const findStreams = () => {
    if (values.course) {
      let unv: any = streamData?.filter(
        (u) => u.university_id === values.university
      );
      let crs: any = unv?.filter((u: any) => u.course_id === values.course);

      let distStm: any = findDistinct(crs, "stream_id");
      if (distStm?.length) {
        return distStm?.map((a: any) => {
          return {
            ...a,
            value: a.stream_id,
            label: a.stream_name,
          };
        });
      }
    } else {
      return [];
    }
  };
  const findSemesters = () => {
    if (values.stream) {
      let unv: any = streamData?.filter(
        (u) => u.university_id === values.university
      );
      let crs: any = unv?.filter((u: any) => u.course_id === values.course);
      let str: any = crs?.filter((u: any) => u.stream_id === values.stream);

      let distSem: any = findDistinct(str, "semester_id");
      if (distSem?.length) {
        return distSem?.map((a: any) => {
          return {
            ...a,
            value: a.semester_id,
            label: a.semester_name,
          };
        });
      }
    } else {
      return [];
    }
  };
  const findSubject = () => {
    if (values.semester) {
      let unv: any = streamData?.filter(
        (u) => u.university_id === values.university
      );
      let crs: any = unv?.filter((u: any) => u.course_id === values.course);
      let str: any = crs?.filter((u: any) => u.stream_id === values.stream);
      let sem: any = str?.filter((u: any) => u.semester_id === values.semester);

      let distSub: any = findDistinct(sem, "subject_id");
      if (distSub?.length) {
        return distSub?.map((a: any) => {
          return {
            ...a,
            value: a.subject_id,
            label: a.subject_name,
          };
        });
      }
    } else {
      return [];
    }
  };

  const categoryOptions = [
    {
      label: "Notes",
      value: "Notes",
    },
    {
      label: "Syllabus",
      value: "Syllabus",
    },
    {
      label: "PYQ",
      value: "PYQ",
    },
    {
      label: "Model_questions",
      value: "Model_questions",
    },
    {
      label: "Projects",
      value: "Projects",
    },
    {
      label: "Seminars",
      value: "Seminars",
    },
    {
      label: "Magazines",
      value: "Magazines",
    },
  ];

  console.log(values);

  return (
    <Modal
      opened={props.isOpenModal}
      onClose={() => {
        reset();
        props.onReset();
        resetState();
      }}
      title={props.data ? "Update Note" : "Create New Note"}
      centered
      size={"xl"}
      closeOnClickOutside={false}
    >
      <form
        onSubmit={onSubmit((payload) => {
          const hashtags = payload.hashtags;
          const note_category = payload.note_category;
          const university = payload.university;
          const course = payload.course;
          const stream = payload.stream;
          const semester = payload.semester;
          const subject = payload.subject;

          payload.hashtags = hashtags.toString();
          payload.note_category = note_category;
          payload.university = university?.toString();
          payload.university = [university];
          payload.course = [course];
          payload.stream = [stream];
          payload.semester = [semester];
          payload.subject = [subject];
          payload.channel_name = props.channels.find(
            (item: { channel_id: Number; name: string }) =>
              item.channel_id === payload.channel_id
          ).name;
          payload.channel_thumbnail = props.channels.find(
            (item: { channel_id: Number; name: string; thumbnail: string }) =>
              item.channel_id === payload.channel_id
          ).thumbnail;

          let post_payload: any = Object.assign(payload, {
            normal_note: normalPostData,
            youtube: youtubeData,
            video: videoData,
            audio: audioData,
          });

          if (payload.id) {
            mutUpdateNote({ payload: post_payload, id: payload.id });
          } else mutCreatePost(post_payload);
          reset();
        })}
      >
        <Grid justify={"flex-start"} align="center">
          <Grid.Col span={6}>
            <Select
              label="Channel"
              placeholder="Select Channel"
              value={values.channel_id?.toString()}
              data={
                Array.isArray(props.channels)
                  ? props.channels.map(
                      (item: { channel_id: Number; name: string }) => ({
                        value: item.channel_id.toString(),
                        label: item.name,
                      })
                    )
                  : []
              }
              onChange={(value) => setFieldValue("channel_id", Number(value))}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <MultiSelect
              label="Hashtags"
              placeholder="select hashtags"
              value={values.hashtags as string[]}
              data={
                Array.isArray(props.hashtagData)
                  ? props.hashtagData.map(
                      (item: { id: Number; hashtag: string }) => ({
                        value: item.hashtag,
                        label: item.hashtag,
                      })
                    )
                  : []
              }
              onChange={(value) => setFieldValue("hashtags", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Note Categories"
              placeholder="select Note categories"
              value={values.note_category}
              data={categoryOptions}
              onChange={(value) => setFieldValue("note_category", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Listing Order"
              placeholder="listing order"
              value={values.listing_order}
              onChange={(value) => setFieldValue("listing_order", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Link Open"
              placeholder="select an option"
              value={values.link_open}
              data={[
                { value: "none", label: "None" },
                { value: "inside", label: "Inside" },
                { value: "outside", label: "Outside" },
                { value: "whatsapp", label: "WhatsApp" },
              ]}
              onChange={(value) => setFieldValue("link_open", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Note Link"
              placeholder="Note Link"
              value={values.link}
              onChange={(event) =>
                setFieldValue("link", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              label="Title"
              placeholder="Title"
              value={values.note_title}
              onChange={(event) =>
                setFieldValue("note_title", event.currentTarget.value)
              }
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              label="Description"
              placeholder="Description"
              value={values.description}
              onChange={(event) =>
                setFieldValue("description", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Prepared By"
              placeholder="Prepared By"
              value={values.prepared_by}
              onChange={(event) =>
                setFieldValue("prepared_by", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Modules Covered"
              placeholder="Modules Covered"
              value={values.modules_covered}
              onChange={(event) =>
                setFieldValue("modules_covered", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Average Rating"
              placeholder="Average Rating"
              type="number"
              value={values.avg_rating}
              onChange={(event) =>
                setFieldValue(
                  "avg_rating",
                  parseFloat(event.currentTarget.value)
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Total Rating"
              placeholder="Total Rating"
              type="number"
              value={values.total_rating}
              onChange={(event) =>
                setFieldValue(
                  "total_rating",
                  parseFloat(event.currentTarget.value)
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Note Image"
              placeholder="Note Image"
              value={values.note_image}
              onChange={(event) =>
                setFieldValue("note_image", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <DatePicker
              placeholder="select date"
              label="Created At"
              inputFormat="DD/MM/YYYY"
              value={values.created_at ? new Date(values.created_at) : null}
              onChange={(value) => {
                setFieldValue("created_at", dayjs(value).format("YYYY-MM-DD"));
              }}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Share Link"
              placeholder="Share Link"
              value={values.share_link}
              onChange={(event) =>
                setFieldValue("share_link", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Share Text"
              placeholder="Share Text"
              value={values.share_text}
              onChange={(event) =>
                setFieldValue("share_text", event.currentTarget.value)
              }
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              label="Share Image"
              placeholder="Share Image"
              value={values.share_image}
              onChange={(event) =>
                setFieldValue("share_image", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>

          <Grid.Col span={6}>
            <Select
              label="University"
              placeholder="select university"
              value={values.university}
              data={findUniversities()}
              onChange={(value) => setFieldValue("university", value)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Course"
              placeholder="select course"
              value={values.course}
              data={findCourses()}
              onChange={(value) => setFieldValue("course", value)}
              disabled={!values?.university}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Stream"
              placeholder="select stream"
              value={values.stream}
              data={findStreams()}
              onChange={(value) => setFieldValue("stream", value)}
              disabled={!values?.course}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Semester"
              placeholder="select semester"
              value={values.semester}
              data={findSemesters()}
              onChange={(value) => setFieldValue("semester", value)}
              disabled={!values?.stream}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label="Subject"
              placeholder="select subject"
              value={values.subject}
              data={findSubject()}
              onChange={(value) => setFieldValue("subject", value)}
              disabled={!values?.stream}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Switch
              style={{ paddingTop: 18 }}
              label="Publish  ?"
              checked={values.publish}
              onChange={(event) =>
                setFieldValue("publish", event.currentTarget.checked)
              }
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>

          <Grid.Col span={3} offset={6}>
            <Button
              fullWidth
              onClick={reset}
              mt="md"
              color={"red"}
              variant={"light"}
            >
              Clear
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button fullWidth type="submit" mt="md" variant="light">
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default NotesForm;
