import React, { useState } from "react";
import {
  createStyles,
  AppShell,
  Navbar,
  Header,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  NavLink,
} from "@mantine/core";
import {
  BellRinging,
  Receipt2,
  Logout,
  Settings,
  Users,
  Hash,
  FileAnalytics,
  Building,
  OneTwoThree,
  Book2,
  Abacus,
  Album,
  Notes,
} from "tabler-icons-react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { LocalStore } from "../helper/LocalStore";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon: string = getRef("icon");
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.fn.rgba(theme.colors[theme.primaryColor][8], 0.25)
            : theme.colors[theme.primaryColor][0],
        color:
          theme.colorScheme === "dark"
            ? theme.white
            : theme.colors[theme.primaryColor][7],
        [`& .${icon}`]: {
          color:
            theme.colors[theme.primaryColor][
              theme.colorScheme === "dark" ? 5 : 7
            ],
        },
      },
    },
  };
});

const data = [
  { link: "/channels", label: "Channels", icon: BellRinging },
  { link: "/feeds", label: "Post", icon: Receipt2 },
  { link: "/notes", label: "Notes", icon: Notes },
  {
    link: "/users",
    label: "Users",
    icon: Users,
    submenus: [
      { link: "/registered", label: "Registered Users", icon: Users },
      { link: "/incomplete", label: "Incomplete Users", icon: Users },
      { link: "/removed", label: "Removed Users", icon: Users },
      { link: "/blocked", label: "Blocked Users", icon: Users },
    ],
  },

  { link: "/college", label: "College", icon: Building },
  { link: "/courses", label: "Courses", icon: Album },
  { link: "/streams", label: "Streams", icon: Abacus },
  { link: "/semesters", label: "Semesters", icon: OneTwoThree },
  { link: "/subjects", label: "Subjects", icon: Book2 },
  { link: "/university", label: "University", icon: Building },
  { link: "/appSetup", label: "Settings", icon: Settings },
  { link: "/hashtags", label: "Hashtags", icon: Hash },
  { link: "/feedbacks", label: "Feedbacks", icon: FileAnalytics },
];

export function Landing() {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Channels");
  const [opened, setOpened] = useState(false);

  const disaptch = useAppDispatch();
  const navigate = useNavigate();

  const links = data.map((item) =>
    item.submenus ? (
      <NavLink
        label={item.label}
        icon={<item.icon className={classes.linkIcon} />}
        childrenOffset={28}
      >
        {item.submenus?.map((sub) => (
          <Link
            to={sub.link}
            className={cx(classes.link, {
              [classes.linkActive]: sub.label === active,
            })}
            key={sub.label}
            onClick={(event) => {
              // event.preventDefault();
              setActive(sub.label);
            }}
          >
            <span>{sub.label}</span>
          </Link>
        ))}
      </NavLink>
    ) : (
      <Link
        to={item.link}
        className={cx(classes.link, {
          [classes.linkActive]: item.label === active,
        })}
        key={item.label}
        onClick={(event) => {
          // event.preventDefault();
          setActive(item.label);
        }}
      >
        <item.icon className={classes.linkIcon} />
        <span>{item.label}</span>
      </Link>
    )
  );

  const onLogout = () => {
    LocalStore.removeToken();
    navigate("/login");
  };

  return (
    <>
      <AppShell
        styles={{
          main: {
            background:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        fixed
        navbar={
          <Navbar
            p="md"
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 200, lg: 200 }}
          >
            <Navbar.Section grow>
              {/* <Group className={classes.header} position="apart"> */}
              {/* <MantineLogo /> */}
              {/* <Code sx={{ fontWeight: 700 }}>v3.1.2</Code>
              </Group> */}
              {links}
            </Navbar.Section>

            <Navbar.Section className={classes.footer}>
              <a href="#" className={classes.link} onClick={onLogout}>
                <Logout className={classes.linkIcon} />
                <span>Logout</span>
              </a>
            </Navbar.Section>
          </Navbar>
        }
        // footer={
        //   <Footer height={60} p="md">
        //     Vivar
        //   </Footer>
        // }
        header={
          <Header height={70} p="md">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>

              <Text>Campus Community</Text>
            </div>
          </Header>
        }
      >
        {/* render <Outlet/> here */}
        <Outlet />
      </AppShell>
    </>
  );
}
